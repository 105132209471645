import { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DefaultLayout } from '../layouts/Default';
import { Button } from '../components/Button';
import { PhoneInput } from '../components/PhoneInput';
import { useTimer } from '../hooks/useTimer';

import { ParkingContext } from '../App.js';
import ROUTES from '../datasets/routes';
import useGatekeeper from '../hooks/useGatekeeper';

export function EnterPhone() {
    const navigate = useNavigate();
    const { state } = useLocation();
    
    const { reset: resetTimer, destroy: destroyTimer } = useTimer(() => { navigate(ROUTES.START); }, 20000);
    const parkingData = useContext(ParkingContext);
    const gatekeeper = useGatekeeper();
    
    const [phone, setPhone] = useState('');
    const [disabledOpenGate, setDisabledOpenGate] = useState(true);
    const [disabledKeyboard, setDisabledKeyboard] = useState(false);

    async function openGate() {
        try {
            setDisabledOpenGate(true);
            setDisabledKeyboard(true);

            gatekeeper.open(parkingData.eula.version, phone);
            
            navigate(ROUTES.START);
        } catch(err) {
            console.log(err);
            navigate(ROUTES.ERROR);
        }
    }

    return (
        <DefaultLayout>
            <div className='flex flex-col justify-between h-full'>
                <h2 className='font-semibold text-[32px]'>Enter your phone number</h2>
            
                <div className='flex-1 flex items-center justify-center'>
                    <PhoneInput
                        value={phone}
                        onInput={(phone) => {setPhone(phone)}}
                        resetTimer={resetTimer}
                        setDisabledOpenGate={setDisabledOpenGate}
                        disabledKeyboard={disabledKeyboard}
                    />
                </div>
            
                <div className='flex justify-between items-center space-x-3 pt-3'>
                    <Button
                        onClick={() => { destroyTimer(); navigate(state?.fromEula ? ROUTES.EULA : ROUTES.START); }}
                        disabled={disabledKeyboard}
                        type='secondary'
                    >
                        Back
                    </Button> 
                    <Button
                        disabled={disabledOpenGate}
                        onClick={() => {destroyTimer(); openGate();}}
                        type='primary'
                    >
                        { disabledKeyboard ? 'Opening...' : 'Open Gate'}
                    </Button> 
                </div> 
            </div>
        </DefaultLayout>
    )
}