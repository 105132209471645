import { useNavigate } from 'react-router-dom';
import { WarningLayout } from '../layouts/Warning';
import { Button } from '../components/Button';
import { ReactComponent as WarningIcon } from '../images/warning.svg';
import ROUTES from '../datasets/routes';

export function Error() {

    const navigate = useNavigate();

    return (
        <WarningLayout>
            <div className='h-full bg-red-800'>
                <div className="flex flex-col items-center justify-around h-full max-w-[1224px] mx-auto px-32">
                    <span></span>
                    <WarningIcon/>

                    <div className="text-center">
                        <h1 className="text-5xl text-white font-semibold">Something went wrong</h1>
                        <p className="text-2xl text-white font-semibold">Call supervisor</p>
                    </div>

                    <Button type="warning-light" onClick={() => { navigate(ROUTES.START); }}>Or click here to try again</Button> 
                </div>
            </div>
        </WarningLayout>
    )
}