import { useEffect } from "react";
import Pusher from 'pusher-js';

const pusher = new Pusher(
	process.env.REACT_APP_PUSHER_APP_KEY,
	{
		cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER
	}
);

// Enable pusher logging only in development
if (process.env.NODE_ENV === 'development') Pusher.logToConsole = true;

export function usePusher(channelName, callbacks) {

    useEffect(() => {
		const channel = pusher.subscribe(channelName);

        Object.keys(callbacks).forEach(
            (event) => {
                channel.bind(event, callbacks[event]);
            }
        );
	}, []);

	return [pusher, channelName];
}