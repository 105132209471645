import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePusher } from '../hooks/usePusher';
import { DefaultLayout } from '../layouts/Default';
import { Button } from '../components/Button';
import { ReactComponent as StartSVG } from '../images/start.svg';
import useGatekeeper from '../hooks/useGatekeeper';

import { ParkingContext } from '../App.js';
import ROUTES from '../datasets/routes';

export function Start() {
    const generateRandNum = () => {
        return Math.floor((Math.random()*1000) + 1);
    }

    const navigate = useNavigate();
    const [randNum, setRandNum] = useState(generateRandNum())
    const gatekeeper = useGatekeeper();

    const parkingData = useContext(ParkingContext);

    const openBarrier = () => {
        try {
            gatekeeper.open(parkingData.eula.version);
        } catch (ex) {
            navigate(ROUTES.ERROR);
        }
    }

    const refreshLogo = () => {
        setRandNum(generateRandNum());
    }

    usePusher(
        process.env.REACT_APP_PUSHER_CHANNEL,
        {
            'parking-updated' : () => { refreshLogo();},
        }
    );

    return (
        <DefaultLayout>
            <div className='flex flex-col justify-between items-center h-full'>
                <img className='mx-auto w-[200px] h-[100px] object-contain' alt='' 
                        src={`${process.env.REACT_APP_BACKEND_URL}/storage/logos/parking_lot_logo.png?${randNum}`} 
                          onError={(e)=>{e.target.onerror = null; e.target.className="hidden"}}/> 
                <div className='flex flex-col flex-1 items-center justify-center space-y-6'>
                    <StartSVG className='mx-auto max-w-full'/>
                    <h1 className='font-semibold text-5xl text-center'>{parkingData?.name ? `Welcome to ${parkingData.name}` : 'Welcome!'}</h1>  
                </div> 
                <Button onClick={() => navigate(parkingData.promptPhone ? ROUTES.ENTER_PHONE : openBarrier())} type="primary">Click here to accept the terms and conditions { parkingData.promptPhone ? '' : 'and enter lot'}</Button> 
                <button onClick={() => navigate(ROUTES.EULA)} className="py-3 px-1 mt-1 text-blue-600 w-max">View terms and conditions</button>
            </div>
        </DefaultLayout>
    )
}