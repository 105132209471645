import axios from 'axios';

export default function useGatekeeper() {
    function retryFailedEntries() {
            const failedEntries = JSON.parse(localStorage.getItem('failedEntries')) || [];
            if ( failedEntries.length ) {
                failedEntries.forEach(
                    async (entry) => {
                        try {
                            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/transactions`, entry);
                            failedEntries.splice(
                                failedEntries.findIndex(e => e.phone_number === entry.phone_number),
                                1
                            );
                            localStorage.setItem('failedEntries', JSON.stringify(failedEntries));
                        } catch(err) {
                            console.log(err);
                        }
                    }
                );
            }
        }

    async function registerVehicleEntry(currentEntry) {  
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/transactions`, currentEntry);
        } catch(err) {
            localStorage.setItem('failedEntries', JSON.stringify(
                [
                    ...JSON.parse(localStorage.getItem('failedEntries')) || [],
                    {
                        ...currentEntry,
                        date: (new Date()).toISOString()
                    }
                ]
            ));
        }
    }

    return {
        async open(eulaVersion, phone) {
            if (process.env.REACT_APP_PARKING_WITH_PROXY === '1') {
                //Call endpoint in backoffice to open gate...
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/parking/open-barrier`);
                console.log(response);
            } else {
                //Using fetch instead of axios to avoid sending Auth header
                const response = await fetch(`${process.env.REACT_APP_LOCAL_NETWORK}/state.xml?relay1State=2`, {
                    mode: 'no-cors'
                  });
                console.log(response);
            }

            await registerVehicleEntry({
                phone_number: phone,
                version: eulaVersion
            });

            retryFailedEntries();
        }
    }
}