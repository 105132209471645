import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DefaultLayout } from '../../layouts/Default';
import { Button } from '../../components/Button';
import { ParkingContext } from '../../App';
import { useTimer } from '../../hooks/useTimer';
import ROUTES from '../../datasets/routes';
import scopedClasses from './EULA.module.css';
import useGatekeeper from '../../hooks/useGatekeeper';

export function EULA() {

    const navigate = useNavigate();
    const { reset: resetTimer, destroy: destroyTimer } = useTimer(() => { navigate(ROUTES.START); }, 60000);
    const parkingData = useContext(ParkingContext);
    const gatekeeper = useGatekeeper();

    const openBarrier = () => {
        try {
            gatekeeper.open(parkingData.eula.version);
            navigate(ROUTES.START);
        } catch (ex) {
            navigate(ROUTES.ERROR);
        }
    }

    return (
        <DefaultLayout>
            <div className='flex flex-col justify-between h-full'>
                <h2 className='font-semibold text-[32px]'>End-user license Agreement</h2>
                
                <div onScroll={resetTimer} className={scopedClasses.eula} dangerouslySetInnerHTML={{__html: parkingData.eula.text }}></div>
                
                <div className='flex justify-between items-center space-x-3 pt-3'>
                    <Button onClick={() => { destroyTimer(); navigate(ROUTES.START); }} type='secondary'>Decline</Button> 
                    <Button onClick={() => { destroyTimer(); parkingData.promptPhone ? navigate(ROUTES.ENTER_PHONE, { state: { fromEula: true } }) : openBarrier() }} type='primary'>Accept Agreement</Button> 
                </div> 
            </div>
        </DefaultLayout>
    )
}