import { useEffect } from "react";

let timer;

export function useTimer(callback, timeWindow) {
    
    useEffect(() => { start(); }, []);

    function start() {
        timer = setTimeout(callback, timeWindow);
    }

    function reset() {
        clearTimeout(timer);
        start();
    }
        
    function destroy() {
        clearTimeout(timer);
    }

    return {
        reset,
        destroy
    }

}