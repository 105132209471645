import { useState, useEffect, createContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { Start } from './pages/Start';
import { EULA } from './pages/EULA';
import { EnterPhone } from './pages/EnterPhone';
import { Error } from './pages/Error';
import { Closed } from './pages/Closed';

import axios from 'axios';
import moment from 'moment';
import { usePusher } from './hooks/usePusher';
import { offlineConfig } from './datasets/offlineConfig';
import ROUTES from './datasets/routes';
export const ParkingContext = createContext({});

//Set auth header for all axios requests
const token = process.env.REACT_APP_PARKING_TOKEN;

axios.interceptors.request.use(function (config) {
	config.headers.Authorization = `Bearer ${token}`;
	return config;
});

export function App() {
	const navigate = useNavigate();
	const [ loading, setLoading ] = useState(true);
	const [ parkingData, setParkingData ] = useState({
		name: '',
		eula: {
			text: offlineConfig.defaultEula,
			version: offlineConfig.defaultEulaVersion
		},
		events: [],
		activeEvent: null,
		promptPhone: true
	});

	//Bind callbacks to pusher notifications
	const [pusher, channelName] = usePusher(
		process.env.REACT_APP_PUSHER_CHANNEL,
		{
			'parking-updated' : (data) => { getParkingData();},
			'eula-updated'    : (data) => { getParkingData();},
			'close-parking'   : (data) => {
				setParkingData(
					(prevData) => ({
						...prevData,
						activeEvent: data.parkingEvent
					})
				);
				navigate(ROUTES.CLOSED);
			},
			'open-parking'    : (data) => { navigate(ROUTES.START); },
		}
	);

	//Get parking data and check if should close on init
	//Unsubscribe when app dismount
	useEffect(() => {
		async function initParking() {
			await getParkingData();
			const foundEvent = getActiveEvent();
			if (foundEvent) {
				setParkingData(
					(prevData) => ({
						...prevData,
						activeEvent: foundEvent
					})
				);
				navigate(ROUTES.CLOSED);
			}
			return () => {
				console.log('Unsubscribe from channel');
				pusher.unsubscribe(channelName);
			}
		}
		initParking();
	}, []);

	async function getParkingData() {

		if (!token) return;

		try {
			const { data, request } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/parking`);

			console.log(data, request.status);

			if (request.status === 208) {
				setParkingData(
					(prevData) => ({
						...prevData,
						activeEvent: data
					})
				);
				navigate(ROUTES.CLOSED);
			} else {
				setParkingData(
					(prevData) => ({
						...prevData,
						name: data.name,
						eula: {
							text: data.eulas[0].eula_text.text,
							version: data.eulas[0].version
						},
						events: data.parking_events,
						promptPhone: data.prompts_phone
					})
				);
			}
		} catch(err) {
			console.log('Error getting parking data...', err);
		} finally {
			setLoading(false);
		}
	}

	function getActiveEvent() {
		const now = moment(Date.now()).format('DD-MM-YYYY hh:mm');
		parkingData.events.forEach(
			(event) => {
				if (event.from > now && event.until < now) {
					return event;
				}
			}
		);
		return null;
	}

	return (
		loading ? (
			<div className='flex items-center justify-center h-full'>Loading parking data...</div>
		) : (
			<ParkingContext.Provider value={parkingData}>
				<Routes>
					<Route path={ ROUTES.START } element={<Start />} />
					<Route path={ ROUTES.EULA } element={<EULA />} />
					<Route path={ ROUTES.ENTER_PHONE } element={<EnterPhone />} />
					<Route path={ ROUTES.ERROR } element={<Error />} />
					<Route path={ ROUTES.CLOSED } element={<Closed />} />
				</Routes>
			</ParkingContext.Provider>
		)
	);
}
