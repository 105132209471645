import { useEffect } from "react";
import { ReactComponent as BackspaceIcon } from '../images/backspace.svg';

const digits = ['1','2','3','4','5','6','7','8','9','CLEAR','0'];

export function PhoneInput({ resetTimer, setDisabledOpenGate, disabledKeyboard, onInput:emitChange, value }) {

    //Watch phoneInput value prop
    useEffect(
        () => {
            validate(value);
        },
        [value]
    );

    function insert(digit) {
        resetTimer();
        if (value.length >= 10) {
            return;
        }
        emitChange(value + digit);
    }

    function format(phone) {
        const length = phone.length;

        if (length > 0 && length < 3) return '(' + phone;
        if (length === 3) return '(' + phone + ')';
        if (length > 3 && length <= 6) return `(${phone.substring(0, 3)}) ${phone.substring(3)}`;
        if (length > 6) return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)} - ${phone.substring(6)}`;

        return phone;
    }

    function validate(phone) {
        setDisabledOpenGate(phone.length !== 10);
    }

    function clear() {
        resetTimer();
        emitChange('');
    }

    function backspace() {
        resetTimer();
        emitChange(value.substring(0, value.length - 1));
    }

    return (
        <div className={ classes.phoneInput(disabledKeyboard) }>
            <div className="text-5xl my-5 h-[48px]">{ format(value) }</div>
            <div className="grid grid-cols-3 gap-4 text-center">
                {
                    digits.map(digit => (
                            <button
                                onClick={() => {
                                    digit === 'CLEAR' ? clear() : insert(digit);
                                }}
                                key={digit}
                                className={ classes.digit(digit) }
                                disabled={disabledKeyboard}
                            >
                                { digit }
                            </button>
                        )
                    )
                }
                <button disabled={disabledKeyboard} onClick={backspace} className={ classes.digit(null) }>
                    <BackspaceIcon className="relative -left-[1px]"/>
                </button> 
            </div>
        </div>
    )
}

const classes = {
    phoneInput: (disabled) => `${ disabled && 'opacity-40'} flex flex-col items-center justify-around`,
    digit: (digit) => `
        ${ digit !== 'CLEAR' && 'text-5xl' }
        w-24 h-24 bg-white active:bg-gray-50 border border-gray-300 rounded-full
        flex items-center justify-center
    `
}