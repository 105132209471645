import { useContext } from 'react';
import { WarningLayout } from '../layouts/Warning';
import { ReactComponent as WarningIcon } from '../images/warning.svg';

import { ParkingContext } from '../App.js';

export function Closed() {

    const parkingData = useContext(ParkingContext);

    return (
        <WarningLayout>
            <div className='h-full bg-primary'>
                <div className="flex flex-col items-center justify-center h-full max-w-[1224px] mx-auto px-32">
                    
                    <WarningIcon/>

                    <div className="text-center mt-9">
                        <h1 className="text-5xl text-white font-semibold">Closed</h1>
                        <p className="text-2xl text-white font-semibold">{ parkingData.activeEvent?.message || 'Sorry, we are closed.'}</p>
                    </div>

                </div>
            </div>
        </WarningLayout>
    )
}